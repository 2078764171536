import React, {FC, ReactNode, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Link from "next/link";
import {BlogSVG, CategorySVG, ContactusSVG, Cross20Svg, PagesSVG, TrackingSVG, VektorMenu} from "../../../../../svg";
import {IState} from "../../../../../types/state";
import {mobileMenuClose, mobileMenuOpen} from "../../../../../store/mobile-menu";
import cls from "./customMobileMenu.module.scss"
import CustomMobileLinks from "./CustomMobileLinks";
import CustomCurrDropDown from "../../CustomCurrDropDown";
import CustomMobileMenuFooter from "./CustomMobileMenuFooter";
import {ICustomMobileMenu} from "../../MainLayout";
import Image from "next/image";
import {domainUrl} from "../../../../../helper";

interface ILinkItems {
    linkHref: string,
    key_INTL: string,
}

const LinksItems: FC<ILinkItems> = ({
                                        linkHref,
                                        key_INTL,
                                        // svg
                                    }) => {
    const dispatch = useDispatch();
    return (
        <ul className={cls["mob-links__item"]}>
            <Link href={linkHref}>
                <a onClick={() => dispatch(mobileMenuClose())}>
                    <span className={cls["mobile-links__blok"]}>{key_INTL}</span>
                </a>
            </Link>
        </ul>
    )
}

const CustomMobileMenu: FC<ICustomMobileMenu> = (
    {
        menus,
        categories,
        blogValue,
        salesActiveSettings: {
            Tracking = "",
            ContactWithUs = ""
        } = {},
        translationsData,
        currencies: [{
            code: selectedCurr = "",
            symbol: selectedSymbol = ""
        } = {}, ...currencies] = [],
        phoneValue,
        footerAddressValue,
        footerEmailValue,
        socialsFooter,
        dbName,
        logoPath,
        cacheControlVersion
    }
) => {

    useEffect(() => {
        const handleEvent = () => {
            dispatch(mobileMenuClose())
        }

        window.addEventListener("beforeunload", handleEvent)

        return () => window.removeEventListener('beforeunload', handleEvent)
    }, []);

    const dispatch = useDispatch();
    const [menuAnim, setMenuAnim] = useState(false)
    const mobileMenuOpen2 = useSelector((state: IState) => state.mobileMenu.open);

    const classNamesHandler = {
        "true": cls["mobilemenu--open"],
        "false": ""
    }

    const hasElement: (attributes: { linkHref: string; key_INTL: string; svg: ReactNode })
        => Record<string, ReactNode> = (attributes) => {
        return {
            "true": <LinksItems {...attributes} />,
            "false": null
        }
    }

    const handleCurrencyDropdown = {
        true: <CustomCurrDropDown
            selectedSymbol={selectedSymbol}
            selectedCurr={selectedCurr}
            currencies={currencies}
        />,
        false: null
    }

    useEffect(() => {
        if (menuAnim) {
            const timeout = setTimeout(() => {
                dispatch(mobileMenuClose());
                setMenuAnim((prevState) => !prevState);
            }, 300);
            return () => clearTimeout(timeout);
        }
    }, [menuAnim]);

    const handleMenuClick = () => {
        setMenuAnim((prevState) => !prevState);
    };

    return <div className={`${cls["mobilemenu"]} ${classNamesHandler[`${mobileMenuOpen2}`]}`}>
        <div className={`${cls.mobilemenu__backdrop} ${menuAnim ? `${cls.backdrop__hide}` : ""}`} onClick={handleMenuClick}/>
        <div className={`${cls.mobilemenu__body} ${menuAnim ? `${cls.animtrue}` : ""}`}>
            <div className={cls.menu__header}>
                <div className={cls.header__logo}>
                    <Link href="/">
                        <a>
                            <Image
                                src={domainUrl(`${dbName}/${logoPath}?v=${cacheControlVersion}`)}
                                alt="Logo"
                                width={200}
                                height={40}
                                objectFit="contain"
                                objectPosition="left"
                                unoptimized={true}
                                // TODO !!! this is that part which controlling CWV result
                                priority
                            />
                        </a>
                    </Link>
                </div>
                <button
                    type="button"
                    className={cls["mobile-header__menu-button"]}
                    onClick={handleMenuClick}
                    aria-label="MobileHeaderBtn"
                >
                    <Cross20Svg/>
                </button>
            </div>
            <div className={cls.mobilemenu__content}>
                <CustomMobileLinks
                    links={categories?.[0]?.children}
                    onItemClick={() => dispatch(mobileMenuClose())}
                    svgRender={<CategorySVG/>}
                />
                <CustomMobileLinks
                    links={menus}
                    onItemClick={() => dispatch(mobileMenuClose())}
                    svgRender={<PagesSVG/>}
                />
                {
                    hasElement({
                        linkHref: "/posts",
                        key_INTL: translationsData?.Posts,
                        svg: <BlogSVG/>
                    })[`${!!+blogValue}`]
                }
                {
                    hasElement({
                        linkHref: "/contactwithus",
                        key_INTL: translationsData?.ContactWithUs,
                        svg: <ContactusSVG/>
                    })[`${!!(+ContactWithUs)}`]
                }
                {
                    hasElement({
                        linkHref: "/tracking",
                        key_INTL: translationsData?.Tracking,
                        svg: <TrackingSVG/>
                    })[`${!!(+Tracking)}`]
                }
                {handleCurrencyDropdown[`${currencies.length > 1}`]}
            </div>
            <CustomMobileMenuFooter
                phoneValue={phoneValue}
                footerAddressValue={footerAddressValue}
                footerEmailValue={footerEmailValue}
                socialsFooter={socialsFooter}
                dbName={dbName}
                translationsData={translationsData}
            />
        </div>
    </div>
};


export default CustomMobileMenu

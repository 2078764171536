import {
    UPDATE_CART_DATA,
    SET_PROMOTIONS,
    SET_PROMOTIONS_FREE_DESCRIPTION,
    SET_DISCOUNT_PRICE,
    SET_SHIPPING_TAX,
    CART_REMOVE_ITEM_PENDING,
    CART_REMOVE_ITEM_FULFILLED,
} from "./cartActionTypes";
import shopApi from "../../api/shop";
import {setPopup, setUpCrossProd} from "../general";
import {setShipMethodRate, setShippingZero} from "../checkout";
import {ICartItemType} from "../../types/state";
import {Dispatch} from "react";

const InitialState = {
    formated_grand_total: "",
    formated_sub_total: "",
    items: [],
    minimum_delivery_days: ""
}

/********************** Helpers Part **********************/
const dispatchValueActionHandle = (fetchedCartData: { formated_grand_total: string; items: ICartItemType, minimum_delivery_days: string; formated_sub_total: string }) => {
    const {
        formated_grand_total: grand_total,
        items,
        minimum_delivery_days: max_delivery_days,
        formated_sub_total: sub_total,
    } = fetchedCartData || InitialState;
    return {
        type: UPDATE_CART_DATA,
        payload: {
            grand_total,
            sub_total,
            items,
            max_delivery_days
        },
    }
}

const isShippingZero = (promotions: { free_shipping: number; description: string }[], dispatch: Dispatch<{type: string; payload: any}>) => {
    if (promotions?.length > 0) {
        return promotions.some((promotion) => {
            const { free_shipping, description } = promotion
            if (free_shipping === 1) {
                dispatch(setPromotionsFreeDescription(description))
                return true
            }
            return false
        })
    }
}

const handleTypes = (type: string, payload: any) => { return { type, payload } }

/********************** PROMOTIONS Part **********************/
export const setPromotions = (payload: any[]) => handleTypes(SET_PROMOTIONS, payload);
export const setPromotionsFreeDescription = (payload: string) => handleTypes(SET_PROMOTIONS_FREE_DESCRIPTION, payload);
export const setDiscountPrice = (payload: string) => handleTypes(SET_DISCOUNT_PRICE, payload)
export const setCartShipTaxes = (payload: {
    coupon_code?: string | null;
    coupon_discount?: string;
    subtotal?: string;
    tax: string;
    total?: string
}) => handleTypes(SET_SHIPPING_TAX, payload)

/********************** Cart Actions Part **********************/

export function cartAddItem(
    { id }: { id: number },
    quantity: number,
    { cartToken }: { cartToken: string },
    { token }: { token: string },
    locale: string,
    currCode: string,
    optionsObject?: { customizable_options: Record<string, string | number> } | null,
    addToast?: (error?: boolean) => void,
    links?: number[],
    bundle_options?: Record<string, Array<number>> | null,
    bundle_option_qty?: Record<string, number> | null
) {
    return async (dispatch: Dispatch<{ type: string; payload: { grand_total: string; items: ICartItemType; sub_total: string }}>) => {
        try {
            const customerToken = token ? { token } : {}
            const body = {
                api_token: cartToken,
                product_id: id,
                quantity: quantity,
                qty: quantity,
                ...customerToken,
                customizable_options: optionsObject?.customizable_options,
                locale,
                currency: currCode,
                links,
                bundle_options,
                bundle_option_qty
            };
            const { data } = await shopApi.cartAddItem({productId: id, body, addToast}) || {};
            dispatch(dispatchValueActionHandle(data))
        } catch (error) {
            console.log(error);
        }
    }
}

export function cartRemoveItem(itemId: number, { cartToken }: { cartToken: string }, customer: { token: string }) {
    return async (dispatch: Dispatch<{ type: string; grand_total?: string; items?: ICartItemType }>) => {
        type loaderPayloadType = { type: string, payload: number };
        dispatch({ type: CART_REMOVE_ITEM_PENDING, payload: itemId } as loaderPayloadType);
        try {
            const handleQueryParam = () => {
                let loggedIn = ""
                if (customer?.token) {
                    loggedIn = `&token=${customer.token}`
                }
                return `?api_token=${cartToken}${loggedIn}`
            }
            const { data } = await shopApi.cartRemoveItem({ productId: itemId, queryParam: handleQueryParam()})
            const { promotions, formated_base_discount } = data || {};
            dispatch(setShippingZero(isShippingZero(promotions, dispatch)))
            dispatch(setPromotions(promotions))
            dispatch(setDiscountPrice(formated_base_discount))
            dispatch(dispatchValueActionHandle(data))
            dispatch({ type: CART_REMOVE_ITEM_FULFILLED, payload: itemId } as loaderPayloadType);
        } catch (error) {
            dispatch({ type: CART_REMOVE_ITEM_FULFILLED, payload: itemId } as loaderPayloadType);
            console.log(error)
        }
    }
}

export function cartUpdateQuantities(
    quantities: {itemId: number; value: number}[],
    customerToken: { token: string },
    { cartToken }: { cartToken: string }
) {
    const qty: Record<number, number> = {};
    quantities.forEach(({ itemId, value }) => {
        qty[itemId] = value
    })
    return async (dispatch: Dispatch<{type: string; payload: { grand_total: string; items: ICartItemType; sub_total: string}}>) => {
        try {
            const customerTokenSetter = customerToken?.token ? {token: customerToken.token} : {}
            const body = {
                ...customerTokenSetter,
                api_token: cartToken,
                qty: qty,
            };
            const { data } = await shopApi.cartQuantityUpdate({body})
            dispatch(dispatchValueActionHandle(data))
        } catch (error) {
            console.log(error)
        }
    }
}
export function getCartDataMount(cartToken: string, customer: { token: string }, locale: string, currCode: string) {
    return async (dispatch: Dispatch<{ type: string; grand_total?: string; items?: ICartItemType }>) => {
        try {
            const customerToken = customer && customer?.token ? `&token=${customer.token}` : ""
            const { data } = await shopApi.getCartData({ cartToken, customerToken, locale, currCode })
            const { selected_shipping_rate } = data || {}
            const { formated_price } = selected_shipping_rate || {};
            dispatch(setShipMethodRate(formated_price))
            dispatch(dispatchValueActionHandle(data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const getUpOrCrossProd = (prodID: number, type: string, locale: string, selectedRate: {
    exchange_rate: { rate: string },
    symbol: string,
    code: string
}) => {
    return async (dispatch: Dispatch<{ type: string; grand_total?: string; items?: ICartItemType }>) => {
        try {
            const res = await shopApi.getUpOrCrossSellProducts(prodID, locale, selectedRate, type);
            if (res?.length > 0) {
                dispatch(setUpCrossProd(res))
                dispatch(setPopup(true))
            }
            return res
        } catch (error) {
            console.log(error)
        }
    }
}

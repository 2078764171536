import React, {FC, JSX, memo, ReactNode, useCallback, useEffect, useRef, useState} from "react";
import cls from "../customHeader.module.scss"
import {
    ActiveUserSvg,
    CartActive,
    CartFill,
    HeartActive,
    HeartSvg,
    HomeMobile,
    HomeMobileActive,
    PersonFill,
    SearchssSvg,
    SignedInUser
} from "../../../../../svg";
import IndicatorButton from "./CustomIndicatorButton";
import {useRouter} from "next/router";
import {useSelector} from "react-redux";
import {IState} from "../../../../../types/state";

interface ICustomIndicator {
    icon: string;
    className: string;
    url: string;
    dropdown: string | JSX.Element;
    value: string | number;
    iconWrapperClassName: string;
    title: string
}

const CustomIndicator: FC<ICustomIndicator> = (
    {
        dropdown,
        url,
        className = "",
        icon,
        value,
        iconWrapperClassName = "",
        title: propsTitle
    }
) => {

    const companyName = useSelector((state: IState) => state.customer.companyName);

    const indicatorIcons = (style: Record<string, string>): Record<string, JSX.Element> => ({

        homeIcon: <HomeMobile style={{pointerEvents: "none", ...style}}/>,
        homeIcon_active: <HomeMobileActive style={{pointerEvents: "none", ...style}}/>,
        cartIcon: <CartFill style={{pointerEvents: "none", ...style}}/>,
        cartIcon_active: <CartActive style={{pointerEvents: "none", ...style}}/>,
        heartIcon: <HeartSvg style={{pointerEvents: "none", ...style}}/>,
        heartIcon_active: <HeartActive style={{pointerEvents: "none", ...style}}/>,
        userIcon: <PersonFill className={cls.user_svg} style={{pointerEvents: "none", ...style}}/>,
        userIcon_active: <div className={cls.authenticated_item}>
            <ActiveUserSvg style={{pointerEvents: "none", ...style}}/>
            <span className={cls.company_name}>{companyName}</span>
        </div>,
        searchIcon: <SearchssSvg style={{pointerEvents: "none", width: "24px", height: "24px"}}/>,
    })

    const [opens, setOpen] = useState<boolean>(false);
    const router = useRouter()

    useEffect(() => {
        setOpen(false)
    }, [router]);

    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const additionalClassName = {
        "true": cls[iconWrapperClassName],
        "false": ""
    }
    const valueGreaterThan = {
        "true": "99+",
        "false": value
    }

    const handleClassname = {
        "true": `${cls["indicator--opened"]}`,
        "false": ""
    }
    const totalValue = <span
        className={`${cls.indicator__value} ${cls.mobile_indicator__value}`}>{valueGreaterThan[`${value > 99}`]}</span>

    useEffect(() => {
        const handleOutsideClick = (e: any) => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                setOpen(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick)

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
    }, []);

    const handleButtonClick = (event: { preventDefault: () => void }) => {
        const hasDropdown = {
            "true": () => event.preventDefault(),
            "false": () => {
            }
        }
        hasDropdown[`${!!dropdown}`]()
        setOpen((prevState) => !prevState);
    }

    const titleHandler = {
        "true": {
            "true": totalValue,
            "false": totalValue
        },
        "false": {
            "true": totalValue,
            "false": null
        }
    }
    const {props: {children: totalValChildren = ""}} = totalValue
    const title: ReactNode = (
        <span className={`${cls.indicator__area} ${additionalClassName[`${!!iconWrapperClassName}`]}`}>
            {indicatorIcons({})[`${icon}`]}
            {titleHandler[`${totalValChildren > 0}`][`${totalValChildren === "99+"}`]}
        </span>
    )

    const hasDropDown = {
        "true": (
            <div className={cls.indicator__dropdown}>
                <div
                    className={`${cls["indicator"]} ${cls["indicator--trigger--click"]} ${className} ${handleClassname[`${opens}`]}`}>
                    {dropdown}
                </div>
            </div>
        ),
        "false": null
    }


    return (
        <div
            className={`${cls["indicator"]} ${cls["indicator--trigger--click"]} ${className} ${handleClassname[`${opens}`]}`}
            ref={wrapperRef}
        >
            <IndicatorButton
                titleSpan={propsTitle}
                title={title}
                url={url}
                handleButtonClick={handleButtonClick}
            />
            {hasDropDown[`${!!dropdown}`]}
        </div>
    );
};

export default CustomIndicator;
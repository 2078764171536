import cls from "./customHeader.module.scss"
import React, {FC} from "react";
import {ISecondHeader} from "../MainLayout";
import CustomDepartments from "./CustomDepartments";
import CustomSearch from "./CustomSearch";
import CustomIndicatorPanel from "./CustomIndicators/CustomIndicatorPanel";
import CustomIndicatorCart from "./CustomIndicators/CustomIndicatorCart";
import {useSelector} from "react-redux";
import {IState} from "../../../../types/state";
import Link from "next/link";
import Image from "next/image";
import {domainUrl} from "../../../../helper";

const SecondSection: FC<ISecondHeader> = (
    {
        categories,
        isTwoKings,
        dbName,
        selectedRate,
        selectedLocale,
        translationsData,
        backOrderValue,
        isSocialLinksActive,
        allowCheckoutValue,
        logoPath,
        cacheControlVersion,
        isMobile,
        defaultLangCode
    }
) => {
    const openSearch = useSelector((state: IState) => state.mobileMenu.searchOpen)
    const classSearchOpen = {
        true: `${cls["mobile-header__search--open"]} ${cls["mobile-header__search"]}`,
        false: cls["mobile-header__search"]
    }

    return (
        <div className={cls.second_section}>
            <div className={`${cls["nav-panel"]}`}>
                <div className={`${cls["nav-panel__container"]} container`}>
                    <div className={`${cls["nav-panel__row"]}`}>
                        <div className={cls.logo_div}>
                            <Link href="/">
                                <a>
                                    <Image
                                        src={domainUrl(`${dbName}/${logoPath}?v=${cacheControlVersion}`)}
                                        alt="Logo"
                                        width={200}
                                        height={45}
                                        objectFit="contain"
                                        objectPosition="left"
                                        unoptimized={true}
                                        // TODO !!! this is that part which controlling CWV result
                                        priority
                                    />
                                </a>
                            </Link>
                        </div>
                        <div className={`${cls["site-header__search"]} ${classSearchOpen[`${openSearch}`]}`}>
                            <CustomSearch
                                translationsData={translationsData}
                                selectedLocale={selectedLocale}
                                dbName={dbName}
                                selectedRate={selectedRate}
                                backOrderValue={backOrderValue}
                                defaultLangCode={defaultLangCode}
                            />
                        </div>
                        <div className={`${cls["nav-panel__indicators"]}`}>
                            <CustomIndicatorPanel
                                isSocialLinksActive={isSocialLinksActive}
                                translationsData={translationsData}
                                selectedLocale={selectedLocale}
                                selectedRate={selectedRate}
                                dbName={dbName}
                                isMobile={isMobile}
                                allowCheckoutValue={allowCheckoutValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SecondSection;